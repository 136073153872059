@import "@nationwide-bolt/css-core/dist/css/bolt-core";
@import "@nationwide-bolt/tokens/dist/scss/tokens";
@import "@nationwide-bolt/adapter-material/dist/bolt-material-theme.css";

html{
  min-width: 320px;
  overflow-y: scroll;
}

.smartride-background {
  background-color: #eeeff1;
}

.smartride-details-background {
  background-color: #fff;
}

.smartmiles-background {
  background-color: #e7e7e7;
  min-height: 50vh;
  height: auto;
}

body {
	padding: 0;
	margin: 0;
  display: flex;
  display: -webkit-flex;
  font-family: "proxima-nova", Arial, "Helvetica Neue", Helvetica, sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 800px;
  min-width: 320px;
  background-color: #e7e7e7;
  flex-direction: column;
}
a.grey-bkgd{
  color: #1c57a5!important;
}
a{
  color: #0072cf;
}
a:hover{
  color: #003b5c;
}

.btn-primary, .btn-outline-primary{
  border-color: #0072cf;
}

.btn-outline-primary{
  color: #0072cf;
}

.btn-outline-primary:hover{
  color: #fff;
}

#tokenCapture{
  display: none;
  top: -1000px;
  right: -1000px;
}

.body-inner {
	padding: 20px;
	background-color: #e7e7e7;
}

.validation-failure {
	background-color: #ffc0c0;
	color: #800000;
	display: inline-block;
	border: 1px solid #800000;
	border-radius: 5px;
	padding: 5px;
	font-weight: bold;
}

img.select-arrow:not(.inactive) {
  cursor: pointer;
}

.inactive{
  opacity: 0.35;
}

button.btn-primary, input.btn-primary{
  background-color: #0072cf;
  border-color: #0072cf;
}

button.btn-outline-primary{
  border-color: #0072cf;
}

@media (max-width: 829.99px) {
  #desktop-timespan{
    display: none;
  }
}
@media (min-width: 766px) {
  .vehicle-name-content{
    display: flex;
    display: -webkit-flex;
    flex-direction:row;
    justify-content:left;
  }
  .vehicle-name-spacer{
    display:block;
    margin-left:.5rem;
    margin-right:.5rem;
  }

}
.vehicle-summary-text-subpages{
  display: flex;
  display: -webkit-flex;
  border-bottom: 1px solid #333333;
  width: 100%;
}
.vehicle-nickname-subpages{
  display: flex;
  display: -webkit-flex;
}

@media(min-width: 992px){


  .vehicle-summary-text-subpages{
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
  }

  .vehicle-nickname-subpages{
    flex-direction: row;
    justify-content: left;
  }
}

@media(max-width: 991.98px) and (min-width:766px){

  .vehicle-summary-text-subpages{
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
  }
  .vehicle-nickname-subpages{
    flex-direction: row;
    justify-content: left;
  }
}

@media(max-width: 765.99px) and (min-width:500px){

  .vehicle-summary-text-subpages{
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    text-align:center;
  }
  .vehicle-nickname-subpages{
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 499.98px) and (min-width:450px){

  .vehicle-summary-text-subpages{
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    text-align:center;
  }
  .vehicle-nickname-subpages{
    flex-direction: row;
    justify-content: center;
  }

  .unit {
    font-size: 20px;
  }
}

@media(max-width: 449.98px){

  .vehicle-summary-text-subpages{
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    text-align:center;
  }

  .vehicle-nickname-subpages{
    flex-direction: row;
    justify-content: center;
  }

  .unit {
    font-size: 20px!important;
  }
}

.vehicle-name-edit-spacer{
  margin-left:.5rem;
  margin-right:.5rem;
}

@media (max-width: 765px) {

  #smiles-header, #vehicle-name-reminder{
    text-align: center;
  }

  .vehicle-name-content{
    display: flex;
    display: -webkit-flex;
    flex-direction:column;
    justify-content:center;
  }
  .vehicle-name-spacer{
    display:none;
    margin-left:.5rem;
    margin-right:.5rem;
  }
}
.dollars,.dollar-sign,.cents, .top-aligned-row{
  vertical-align:top;
  position:relative;
}
.dollars {
  color: #ffffff;
  font-size: 3rem;
  align-self: flex-start;
}
.dollar-sign {
  color: #ffffff;
  font-size: 1.25rem;
  top:.75rem;
  align-self: flex-start;
}
.cents {
  color: #ffffff;
  font-size: 1.25rem;
  align-self: flex-start;
}
.top-aligned-row {
  line-height: 0;
  height: 3rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
}
.top-aligned-row div {
  font-weight: 700;
  vertical-align: top;
  position: relative;
}
.unit{
  font-size:24px;

}
.smiles-header-content{
  display: flex;
  display: -webkit-flex;
  flex-direction:row;
  justify-content:space-between;
}

@media (max-width: 800px) {
  #smiles-header {
    justify-content: center;
  }
}

#smiles-header{
  border-bottom: 1px solid #333333;
  margin: 0rem 0rem .75rem 0rem;
  width: 100%;
}
#smiles-header h1{
  font-weight:700;
}


#vehicle-name-reminder{
  font-weight:300;
}

@media (min-width: 801px) {
  #smiles-header h1{
    font-size:1.5rem;
  }
  #vehicle-name-reminder{
    font-size:1rem;
  }
}
@media (max-width: 800px) {
  #smiles-header h1{
    font-size:1.5rem;
  }
  #vehicle-name-reminder{
    font-size:1rem;
  }
}
#smiles-header .colon{
  font-weight:700;
  margin-right:.5rem;
  margin-left: 0rem;
}
#smiles-header h1 span{
  font-weight:300;
}
#smiles-header h1{
  margin: 1rem 0px .5rem 0;
}

#vehicle-name-reminder{
  width: 100%;
  margin: 1rem 0px 1.25rem 0;
}

#smiles-header a{
  margin: -1rem 0 0 0;
  font-size:1rem;
  line-height: 1rem;
  display:none;
}

.dashboard-header{
  background-color: #ccd8e5;
  margin-top: -16px;
  box-shadow: 0 4px rgba(0, 0, 0, .2);
  padding: .25em 0 .25em 0;
  text-align: center;
  min-height: 45px;
}

.select-arrow{
  width: 30px;
}

.timespan-header{
  color: #003b5c;
  font-weight: bold;
}
.device-info{
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin:0 auto;
}

@media(min-width: 640px){

  .tile-title{
    font-size: 1.125rem;
    line-height:1.125rem;
  }
  #timeline--label .label{
    font-size: 1rem;
    line-height:1.125rem;
  }
  .timeframe{
    font-size: 1.125rem;
    line-height:1.125rem;
  }
  .arrow {
    width: 2.125rem;
    float: left;
    margin-right: .5rem;
    margin-top: .25rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
  }
  .tile-footer{
    margin-top:3px;
    font-size: 1rem;
    line-height: 1.125;
    width:100%;
  }
}

@media(max-width: 639.98px) and (min-width: 460px){

  .tile-title{
    font-size: 1rem;
    line-height:1.125rem;
  }

  #timeline--label .label{
    font-size: 1rem;
    line-height:1.125rem;
  }
  .timeframe{
    font-size: 1rem;
    line-height:1.125rem;
  }
  .arrow {
    width: 1.4rem;
    float: left;
    margin-right: .5rem;
    margin-top: .25rem;
    margin-left: 0rem;
    margin-bottom: .5rem;
  }
  .tile-footer{
    margin: 0rem;
    font-size: 1rem;
    line-height: 1.125;
    width:100%;
  }

}

@media(max-width: 459.9px){

  .tile-title{
    font-size: .875rem;
    line-height:1.1rem;
    margin: 4px!important;
  }

  #timeline--label .label{
    font-size: .875rem;
    line-height:1.1rem;
  }
  .timeframe{
    font-size: .875rem;
    line-height:1.1rem;
  }
  .arrow {
    width: 1.4rem;
    float: left;
    margin-right: .5rem;
    margin-top: .25rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
  }
  .tile-footer{
    margin-top:2px;
    font-size: .85rem;
    line-height: 1.1;
    width:92%;
  }
}


.device-tiles.systemdown .ep-box .unit span::before{
  content: "Unavailable at this time";
}
.device-tiles.nodata .ep-box .unit span::before{
  content: "Loading data, one moment please!";
}
.device-tiles.systemdown .ep-box, .device-tiles.nodata .ep-box{
  color: #e7e7e7!important;
  background-color: #666666!important;
}
.device-tiles.systemdown .tile-title, .device-tiles.nodata .tile-title{
	color: #666666;
}
.device-tiles.systemdown .tile-footer, .device-tiles.nodata .tile-footer, .device-tiles.systemdown .payment-amount {
  display:none;
}

.tile-container{
  /*margin: 1rem;*/
  padding: 1rem;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  width:100%;
}

.tile-title{
  font-weight: bold;
  margin: 0em;
  text-align: center;
}

/*.device-summary{
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}*/

/*Global styles for ng bootstrap accordions*/
@media (min-width:768px) {

  ngb-accordion .accordion-header > a {
    font-size: 1rem;
    line-height: 1.25;
    padding: .75rem 2.5rem .75rem 1.25rem;
  }
}
@media (max-width:767.99px) and (min-width:480px) {

  ngb-accordion .accordion-header > a {
    font-size: .875rem;
    line-height: 1.125;
    padding: .75rem 2.5rem .75rem .875rem;
  }
}
@media (max-width:479.99px) {

  ngb-accordion .accordion-header > a {
    font-size: .875rem;
    line-height: 1.125;
    padding: .75rem 2.5rem .75rem .75rem;
  }
}
ngb-accordion .accordion-header > a {
  width: 100%;  /* This makes the title link consume the whole space of each accordion element header, making the */
  height: 100%; /* entire element header clickable to toggle opening it */
  background-color:#ffffff;
  border-radius: 5px;
}
ngb-accordion .accordion-header > a:hover{
  background-color:#e7e7e7;
}


ngb-accordion .accordion-header {
  padding: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  /*background-color: #FFF;*/
}

ngb-accordion.preferences .accordion-header:hover, ngb-accordion.sre-map .accordion-header:hover{
  background-color: #bfbfbf;
}

ngb-accordion.preferences .accordion-header, ngb-accordion.sre-map .accordion-header{
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

ngb-accordion.preferences span.arrow-up, ngb-accordion.sre-map span.arrow-up {
  border-bottom-color: orangered;
  top: 23px;
}

ngb-accordion.preferences .accordion-body, ngb-accordion.sre-map .accordion-body {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #ebebeb;
}

@media (max-width: 768px) {
  ngb-accordion.preferences .accordion-body, ngb-accordion.sre-map .accordion-body {
    padding: 0.5rem;
  }
}

@media (min-width: 768.01px) {
  ngb-accordion.preferences .accordion-body, ngb-accordion.sre-map .accordion-body  {
    padding: 20px;
  }
}

/* Styles for individual accordions by page. Must reference accordion by "ngb-accordion.[class name]", */
/* otherwise the style won't apply because */

ngb-accordion.daily-summary > .accordion-item{
  border-bottom: 0px solid #0072cf;
  /*background-color: #FFF;*/
  margin: 0;
}

.accordion-item {
  position: relative;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(0,0,0,.0);
  background-clip: border-box;
  border: 0px solid rgba(0,0,0,.125);
  border-radius: 0rem;
}

/* .accordion-header:first-child {
  border-radius: 0 0 0 0!important;
} */

/* Adding 'border-bottom' property from _card.scss that did not carry over with Angular 14 upgrade */
.accordion-header {
  margin-bottom: 0;
  background-color: rgba(0,0,0,.0);
  border-bottom: 1px solid rgba(0,0,0,.125);
}
ngb-accordion.smiles-help .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #0072cf;
}

ngb-accordion.smiles-help .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smiles-help .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smiles-help .accordion-item:first-child {
  border-radius: 0;
}


ngb-accordion.smiles-help .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}
ngb-accordion.smiles-help .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #0072cf;
}
ngb-accordion.smiles-help .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}

ngb-accordion.smiles-help .accordion-item:only-child .accordion-header {
  border: 1px solid #0072cf!important;
  background-color: #FFFFFF;
}


ngb-accordion.smiles-help .accordion-item:last-child .accordion-header {
  border-top: 0!important;
}

ngb-accordion.smiles-help .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #0072cf;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
}

ngb-accordion.smiles-help .accordion-item .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
}
ngb-accordion.smiles-help .accordion-item:last-child .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.smiles-help .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}
ngb-accordion.smiles-help .accordion-header {
  border: 1px solid #0072cf!important;
  background-color: #FFFFFF;
  height: 100px;
}


ngb-accordion.smartmiles-preferences > .accordion-item{
  border-bottom: 0px solid #0072cf;
  /*background-color: #FFF;*/
  margin: 0;
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-header button {
   padding-top: 0.875rem;
   padding-bottom: 0.875rem;
 }

ngb-accordion.smartmiles-preferences .accordion-item:last-child .accordion-header {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.smartmiles-preferences .accordion-item:last-child .accordion-header .collapsed {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-header .collapsed:focus .smartmiles-preference-header{
  text-decoration: underline;
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-header .btn:focus .smartmiles-preference-header {
  text-decoration: underline;
}

/* Angular 14/Bootstrap Button Update for above rule */
ngb-accordion.smartmiles-preferences .accordion-item .accordion-header .accordion-button:focus .smartmiles-preference-header {
  text-decoration: underline;
}

@media (max-width: 500px) {
  ngb-accordion.sride-preferences .accordion-item .accordion-body {
    padding: .5rem;
  }
}

ngb-accordion.sride-preferences .accordion-header:hover {
  background-color: #e7e7e7;
}

ngb-accordion.sride-preferences .accordion-header:focus {
  background-color: #e7e7e7;
}


ngb-accordion.sride-preferences .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #ccc;
}

ngb-accordion.sride-preferences .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #ccc;
  border-top:0px solid #ccc;
}

ngb-accordion.sride-preferences .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #ccc;
  border-top:0px solid #ccc;
}
ngb-accordion.sride-preferences .accordion-item .accordion-header button {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

ngb-accordion.sride-preferences .accordion-item .accordion-header button:hover {
  background-color: #bfbfbf;
  outline: none;
}

ngb-accordion.sride-preferences .accordion-item .accordion-header button:focus .preference-header {
  text-decoration: underline;
}

ngb-accordion.sride-preferences .accordion-item .accordion-header button:focus {
  outline: none;
}


ngb-accordion.sride-preferences .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #ccc;
}
ngb-accordion.sride-preferences .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #ccc;
}
ngb-accordion.sride-preferences .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #ccc;
}

ngb-accordion.sride-preferences .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #ccc;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
}

ngb-accordion.sride-preferences .accordion-item .accordion-body{

background-color: #ebebeb;
border-left:1px solid #ccc;
border-right:1px solid #ccc;
border-bottom:1px solid #ccc;
}

ngb-accordion.sride-preferences .accordion-item:last-child .accordion-body{
  background-color: #ebebeb;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  border-bottom:1px solid #ccc;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.sride-preferences .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}

ngb-accordion.sride-preferences .accordion-header {
  background-color: #d7d7d7;
  border-radius: 2px;
}

ngb-accordion.sride-preferences span.arrow-down{
  border-top-color: #666;
  top: 23px;
}

ngb-accordion.sride-preferences span.arrow-up{
  border-bottom-color: orangered;
  top: 23px;
}



@media (max-width: 500px) {
  ngb-accordion.sride-help .accordion-item .accordion-body {
    padding: 1.25rem;
  }
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-header:hover {
  background-color: #d3d3d3;
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-header:focus {
  background-color: #d3d3d3;
}


ngb-accordion.smartmiles-preferences .accordion-item .accordion-body div {
  outline: none;
}

ngb-accordion.smartmiles-preferences .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-item:first-child {
  border-radius: 0;
}
ngb-accordion.smartmiles-preferences .accordion-item .accordion-header {
  border-right: 1px solid #0072cf;
  border-left: 1px solid #0072cf;
  border-bottom: 1px solid #0072cf;
  border-top: 0;
  border-radius: 0 0 0 0;
  background-color: #FFFFFF;
}
ngb-accordion.smartmiles-preferences .accordion-item:first-child .accordion-header {
  border-radius: .5rem .5rem 0 0;
  border-top: 1px solid #0072cf;
}
ngb-accordion.smartmiles-preferences .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}
ngb-accordion.smartmiles-preferences .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #0072cf;
}
ngb-accordion.smartmiles-preferences .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-item:only-child .accordion-header {
  border: 1px solid #0072cf!important;
  border-radius: .5rem .5rem .5rem .5rem;
  background-color: #FFFFFF;
}

ngb-accordion.smartmiles-preferences .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #0072cf;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-item .accordion-body{
  padding: 1rem;
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
}
ngb-accordion.smartmiles-preferences .accordion-item:last-child .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.smartmiles-preferences .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}


















ngb-accordion.daily-summary .accordion-item .accordion-header button {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

ngb-accordion.daily-summary .accordion-item:last-child .accordion-header {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.daily-summary .accordion-item:last-child .accordion-header .collapsed {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.daily-summary .accordion-item .accordion-header .collapsed:focus .smartmiles-preference-header{
  text-decoration: underline;
}

ngb-accordion.daily-summary .accordion-item .accordion-header .btn:focus .smartmiles-preference-header {
  text-decoration: underline;
}

/* Angular 14/Bootstrap Button Update for above */
ngb-accordion.daily-summary .accordion-item .accordion-header .accordion-button:focus .smartmiles-preference-header {
  text-decoration: underline;
}

ngb-accordion.daily-summary .accordion-item .accordion-header:hover {
  background-color: #d3d3d3;
}

ngb-accordion.daily-summary .accordion-item .accordion-header:focus {
  background-color: #d3d3d3;
}


ngb-accordion.daily-summary .accordion-item .accordion-body div {
  outline: none;
}

ngb-accordion.daily-summary .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #0072cf;
}

ngb-accordion.daily-summary .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.daily-summary .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.daily-summary .accordion-item:first-child {
  border-radius: 0;
}
ngb-accordion.daily-summary .accordion-item .accordion-header {
  border-right: 1px solid #0072cf;
  border-left: 1px solid #0072cf;
  border-bottom: 1px solid #0072cf;
  border-top: 0;
  border-radius: 0 0 0 0;
  background-color: #FFFFFF;
}
ngb-accordion.daily-summary .accordion-item:first-child .accordion-header {
  border-radius: .5rem .5rem 0 0;
  border-top: 1px solid #0072cf;
}
ngb-accordion.daily-summary .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}
ngb-accordion.daily-summary .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #0072cf;
}
ngb-accordion.daily-summary .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}

ngb-accordion.daily-summary .accordion-item:only-child .accordion-header {
  border: 1px solid #0072cf!important;
  border-radius: .5rem .5rem .5rem .5rem;
  background-color: #FFFFFF;
}


/*ngb-accordion.daily-summary .accordion:last-child .accordion-header {
  border-top: 0!important;
}*/

ngb-accordion.daily-summary .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #0072cf;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
}

ngb-accordion.daily-summary .accordion-item .accordion-body{
  padding: 0 0 0rem 0;
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
}
ngb-accordion.daily-summary .accordion-item:last-child .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.daily-summary .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}


ngb-accordion.sride-help .accordion-header:hover {
  background-color: #e7e7e7;
}

ngb-accordion.sride-help .accordion-header:focus {
  background-color: #e7e7e7;
}


ngb-accordion.sride-help .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #ccc;
}

ngb-accordion.sride-help .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #ccc;
  border-top:0px solid #ccc;
}

ngb-accordion.sride-help .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #ccc;
  border-top:0px solid #ccc;
}
ngb-accordion.sride-help .accordion-item .accordion-header button {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

ngb-accordion.sride-help .accordion-item .accordion-header button:hover {
  background-color: #bfbfbf;
  outline: none;
}

ngb-accordion.sride-help .accordion-item .accordion-header button:focus {
  outline: none;
}

ngb-accordion.sride-help .accordion-item .accordion-header button:focus .header-text {
  text-decoration: underline;
}


ngb-accordion.sride-help .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #ccc;
}
ngb-accordion.sride-help .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #ccc;
}
ngb-accordion.sride-help .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #ccc;
}

ngb-accordion.sride-help .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #ccc;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
}

ngb-accordion.sride-help .accordion-item .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #ebebeb;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  border-bottom:1px solid #ccc;
}
ngb-accordion.sride-help .accordion-item:last-child .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #ebebeb;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  border-bottom:1px solid #ccc;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.sride-help .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}

ngb-accordion.sride-help .accordion-header {
  background-color: #d7d7d7;
  border-radius: 2px;
}

ngb-accordion.sride-help span.arrow-down{
  border-top-color: #666;
  top: 23px;
}

ngb-accordion.sride-help span.arrow-up{
  border-bottom-color: orangered;
  top: 23px;
}









ngb-accordion.sre span.arrow-up{
  border-bottom-color: orangered;
  top: 23px;
}

ngb-accordion.sre span.arrow-down{
  border-top-color: #666;
  top: 23px;
}

ngb-accordion.sre > .accordion-item:first-child{
  border-radius: .5rem .5rem 0 0;
}

ngb-accordion.sre > .accordion-item:last-child{
  border-radius: 0 0 .5rem .5rem;
}

ngb-accordion.sre .accordion-body{
  padding: 1.7rem;
  background-color: #ebebeb;
}

ngb-accordion.sre #successMap{
  border: 1px solid black;
}

ngb-accordion.sre .accordion-header {
  border-bottom: 1px solid #FFF;
  background-color: #ffffff;
  font-weight: bold;
}

ngb-accordion.sre .accordion-header > a{
  padding: .75rem 2.5rem .75rem 1.25rem;
  color: #0072cf;
}

ngb-accordion.smartmiles-preferences .accordion-header {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(28, 87, 165);
}
ngb-accordion.smartmiles-preferences .accordion-header > a{
  padding: 1rem;
}
ngb-accordion.smartmiles-preferences .accordion-body, ngb-accordion.daily-summary .accordion-body{
  padding: 1.25rem;
  background-color: #d7d7d7;
}
ngb-accordion.preferences .accordion-header {
  background-color: #d7d7d7;
  border-radius: 2px;
}

ngb-accordion.preferences .accordion-header > a{
  padding: 1rem;
}

ngb-accordion.preferences span.arrow-down{
  border-top-color: #666;
  top: 23px;
}

.mb-0 {
  width: 100%;
  font-size: 1.35rem;
}

.btn-link {
  text-align: -webkit-left;
  text-align: left;
  text-align: -moz-left;
  width: 100%;
  white-space: normal;
  text-decoration: none!important;
}

/* Angular 14/Bootstrap Button Update for above rule*/
/* Style rule below is a combination of .btn-link and .btn classes from button.scss that applied in Angular 12 */
.accordion-button {
  text-align: -webkit-left;
  text-align: left;
  text-align: -moz-left;
  width: 100%;
  white-space: normal;
  text-decoration: none !important;
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}


input.pref-edit{
  max-width: 300px;
}

@media (max-width: 600px) {
  input.pref-edit {
    max-width: 80%;
  }
}

input.pref-edit-smiles {
  border: 0;
}

.accordion-header a{
  color: black;
  text-decoration: none;
}
#mainContainer{
  background-color: #F5F5F5;
  padding-bottom: 1.5em;
  width: 100%;
  min-height: calc(100vh - 425px);
  overflow-x: inherit;
  overflow-y: inherit;
  max-width: none;
}

.form-control{
  border: 1px solid #0072cf;
  height: 2.2rem;
}

.form-control.error{
  border: 1px solid #9e2a2b!important;
}

.form-control.error:focus{
  box-shadow: 0 0 0 0.2rem rgba(255, 17, 0, 0.25)
}

/* Chat Bot css refinements */
.bot-header, .bot-header-button, #BotDevWidget .wc-chatview-panel, #BotDevWidget .wc-console, .bot-header button, #BotDevWidget .wc-upload, #BotDevWidget .wc-send{
  background-color:#003c5c;
}
#BotDevWidget .wc-console{
  border-color:#003c5c;
}

.bot-header .bot-close {
  border-left: 1px solid rgba(255,255,255,.05);
  border-top-right-radius: 15px;
  border-left: 1px solid rgba(255,255,255,.05);
  color: #ffffff;
}

.bot-header button:hover, #BotDevWidget .wc-send:hover {
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
}

#BotDevWidget .wc-send svg {
  fill: #ffffff;
}

div.tooltip-inner{
  color: #FFFFFF;
  background-color: #1c57a5;
  padding: 15px;
  width: 195px;
  margin-top: -10px;
}

@media(min-width: 768px){
  div.tooltip-inner {
    margin-left: 20px;
  }
  .tooltip .arrow{
    left: 5%;
  }
}

@media(max-width: 767.98px){
  div.tooltip-inner {
    margin-left: 15px;
  }
  .tooltip .arrow{
    left: 8%;
  }
}

.tooltip.show{
  opacity: 1;
}

.tooltip .arrow{
  top: 16% !important;
}

.tooltip .arrow::before{
  border-width: 1.5rem 1.5rem 1.5rem 0;
  border-right-color: #1c57a5;
}
/* Global container */
@media(max-width: 991.98px) and (min-width: 735px){
  .smiles-main-content{
    padding: 1em 3em;
  }
}
@media (min-width: 768px){
  .smiles-main-content{
      padding: 1em 3em;
    }

    .payment-infographic-faq{
        display: flex;
        flex-direction:row;
        justify-content: space-evenly;
        align-items: center;
    }

    .payment-formula-help{
        padding-top:30px;
        line-height: 0.8;
        outline: 2px solid #a09daf;
        outline-offset: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: 125px;
        width: 150px;
      }
      .payment-formula-mileage-help{
        line-height: 0.8;
        outline: 2px solid #a09daf;
        outline-offset: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 375px;
        height: 200px;
        margin-bottom:10px;
      }
}
@media(min-width: 992px){
  .smiles-main-content{
    padding: 1em 4em;
  }
}
@media(min-width: 1101px){
  .smiles-main-content{
    padding: 1em 4em;
  }
}
@media(min-width: 1170px){
  .smiles-main-content{
    padding: 1em 0em;
    max-width:1024px;
    margin:0 auto;
  }
}
@media(min-width: 1370px){
  .smiles-main-content{
    padding: 1em 0em;
    max-width:1024px;
    margin:0 auto;
  }
}
@media(max-width: 734px) and (min-width: 597px){
    .smiles-main-content{
      padding: 1em 2em;
    }
  }
@media(max-width: 596.9px) and (min-width: 476px){
    .smiles-main-content{
      padding: 1em 1em;
    }
  }
  @media(max-width: 475.99px){
    .smiles-main-content{
      padding: 1em 1em;
    }
  }

ngb-progressbar .progress {
  height: 10px;
  border-radius: 0px;
  background-color: #ccc !important;
  }


ngb-progressbar .bg-info {
  height: 10px;
	background-color:  rgb(91, 198, 232)  !important;
  }

.not-selectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* ------------------------------------------------- */

#graph-main-content .axis path,
#graph-main-content .axis line {
  fill: none;
  stroke: #ff00cc;
  display:none;
}

#graph-main-content .x.axis path,
#graph-main-content .y.axis path{
  stroke: #333333;
  stroke-width:3;
}

#graph-main-content path.domain {
  stroke: none;
}

#graph-main-content .y .tick line {
  stroke: #ddd;
}

/*-------- TOOL TIP ---------*/
#graph-main-content .bar:hover {
  fill: #403A60;
}

#graph-main-content .bar:nth-child(-n+4):after{
  content:"Estimate";
  background:#ccc;
  width:80px;
  height:80px;
}

#graph-main-content #tooltip {
  position: absolute;
  width: 200px;
  height: auto;
  padding: 10px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#graph-main-content #tooltip {
  display: none;
}

#graph-main-content #tooltip p {
  margin: 0;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 20px;
}



#graph-main-content .stop-top {
  stop-color: #f2f2f2;
}

#graph-main-content .stop-bottom {
  stop-color: #009688;
}

#graph-main-content .filled {
  fill: url(#mainGradient);
}
#graph-main-content .bar {
  fill: #6f5091;
}

#graph-main-content .period-list {
  margin:10px 0;
  padding:0;
  position: relative;
  margin-top: 10px;
  margin-bottom:40px;
}
#graph-main-content .period-list li {
  display: inline-block;
  background:#fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border:1px solid #0072CF;
  padding:10px 5px;
  margin-left:30px;
  min-width:100px;
  max-width:105px;
  min-height:40px;
  max-height:40px;
  font-size:0.875em;
  color:#0072CF;
  text-align: center;
  -webkit-box-shadow: 0px 4px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 4px rgba(0,0,0,0.2);
  box-shadow: 0px 4px rgba(0,0,0,0.2);
  box-sizing:border-box;
}
#graph-main-content section.totalmiles {
  margin-top:380px;
  position:relative;
  left:61px;
}
text.mileage-label-active{
  font-weight: bolder;
}
text.date-label {
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
text.sm2_date-label {
    font-family: nw-primary, Gotham, HCo Gotham, Arial, sans-serif;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
}
.sm2_total_graph{
    font-family: nw-primary, Gotham, HCo Gotham, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}
#graph-main-content .graph-main-content {
  /*background-color: aqua;*/
  white-space: nowrap;
  width: 100%;
}
#graph-main-content .left-pane {
  position: fixed;
  left: 0;
  right: 0;
  width:160px;
  height:100%;
  background-color: #f2f2f2;
  z-index:101;
  -webkit-box-shadow: 1px 0px 4px 0px rgba(102,102,102,1);
  -moz-box-shadow: 1px 0px 4px 0px rgba(102,102,102,1);
  box-shadow: 1px 0px 4px 0px rgba(102,102,102,1);
}
[ng\:cloak],[ng-cloak],.ng-cloak{
  display:none !important
}
/* Loading content or message pods */
.loading-pulse {
  animation: pulse 3s infinite;
}
@keyframes pulse {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

.message-pod {
  display: flex;
  display: -webkit-flex;
  flex-direction:column;
  justify-content:start;
  padding:1rem;
  border-radius: .5rem;
  background-color: rgba(0,0,0,.0);
  box-shadow: 0 4px rgba(0, 0, 0, .0);
  -webkit-box-shadow: 0 4px rgba(0, 0, 0, .0);
  -moz-box-shadow: 0 4px rgba(0, 0, 0, .0);
  width:100%;
  margin:1rem 0rem 1.5rem 0rem;
}
.message-header-row{
  display: flex;
  display: -webkit-flex;
  flex-direction:row;
  justify-content:start;
  align-content: center;
}

.message-pod div.message-heading{
  font-weight:700;
}
.message-pod div.message-content{
  font-weight:300;
}
@media(min-width: 768px){
  .message-pod div.message-heading{
    font-size:1.125rem;
  }
  .message-pod div.message-content{
    font-size:1rem;
    line-height:1.125;
  }
  .message-header-icon{
    height:1.875rem;
    width:1.875rem;
    margin-right:.75rem;
    margin-bottom:.25rem;
  }
}
@media(max-width:767.99px){
  .message-pod div.message-heading{
    font-size:1rem;
  }
  .message-pod div.message-content{
    font-size:1rem;
    line-height:1.125;
  }
  .message-header-icon{
    height:1.5rem;
    width:1.5rem;
    margin-right:.5rem;
    margin-bottom:.5rem;
  }
}
#message-info{
  color:#326295;
  border: 1px solid #326295;
}

#message-info .message-header-icon{
  background-image: url("/assets/image/svg/info-dark.svg");
}

#message-default{
  color:#666666;
  border: 1px solid #666666;
}

#message-default .message-header-icon{
  background-image: url("/assets/image/svg/default-dark.svg");
}

#message-alert{
  color:#9e2a2b;
  border: 1px solid #9e2a2b;
}

#message-alert .message-header-icon{
  background-image: url("/assets/image/svg/fail-dark.svg");
}

.tr.alert{
  border-radius: 0;
}


/* SVG style rules */

#speed-graph-draw-area rect {
  fill: #fff;
}

#speed-graph-draw-area .axis {
  shape-rendering: crispEdges;
}

#speed-graph-draw-area .axis path,
#speed-graph-draw-area .axis line {
  fill: none;
}

#speed-graph-draw-area .axis line {
  stroke: #000;
  opacity: 0.1;
  filter: alpha(opacity=10);
  stroke-width: 1px;
}

#speed-graph-draw-area .axis text {
  text-transform: lowercase;
  font-size: .8em;
}

#speed-graph-draw-area .xbg {
  fill: #ccc;
}

#speed-graph-draw-area .ybg {
  cursor: pointer;
  fill: #464747;
}

#speed-graph-draw-area .y.axis text {
  cursor: text;
  fill: #ffffff;
}

#speed-graph-draw-area .y.axis .tick:nth-of-type(1) line {
  stroke-width: 1px;
  opacity: 1;
  filter: alpha(opacity=100);
}

@media(max-width: 500px) {
  #speed-graph-draw-area .x.axis .tick:nth-of-type(3n+0) text {
    display:none;
  }
  #speed-graph-draw-area .x.axis .tick:nth-of-type(3n+1) text {
    display:none;
  }
}
@media(min-width: 500px) {
  #speed-graph-draw-area .x.axis .tick:nth-of-type(2n) text {
    display: none;
  }
}

#speed-graph-draw-area .pane {
  fill: #fff;
  pointer-events: all;
}

#speed-graph-draw-area .nighttime rect {
  fill: #efc8c8;
}

#speed-graph-draw-area .idle {
  fill: #d7d7d7;
}

#speed-graph-draw-area .line {
  fill: none;
  stroke: #999;
  stroke-width: 3px;
}

#speed-graph-draw-area .event {
  fill: #B83731;
}

button.accordion-toggle {
  text-decoration: none!important;
}

#sre-map-display ngb-accordion .accordion-header{
  cursor:default;
  border-bottom: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  border-top: 1px solid #ebebeb !important;
}

#sre-map-display ngb-accordion .accordion-item {
  background-color: #d7d7d7;
}

#sre-map-display ngb-accordion .btn:not(:disabled):not(.disabled) {
  cursor: default;
}

/* Angular 14/Bootstrap Button Update for above rule */
#sre-map-display ngb-accordion .accordion-button:not(:disabled):not(.disabled) {
  cursor: default;
}

#sre-map-display:not(.miles-only) ngb-accordion .accordion-header, #sre-map-display:not(.miles-only) ngb-accordion .accordion-header * {
  cursor: pointer !important;
}
#sre-map-display.miles-only ngb-accordion .accordion-header {
  cursor: default !important;
}

#sre-map-display ngb-accordion .btn{
  line-height:2;
}

/* Angular 14/Bootstrap Button Update for above rule */
#sre-map-display ngb-accordion .accordion-button{
  line-height:2;
}

#sre-map-display ngb-accordion .accordion-item .cardheader button:hover {
  background-color: #bfbfbf;
}

#sre-map-display ngb-accordion .accordion-item .accordion-header button:focus .details-link {
  text-decoration: underline;
  outline: none;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-header button {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

ngb-accordion.smartmiles-help .accordion-item:last-child .accordion-header {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.smartmiles-help .accordion-item:last-child .accordion-header .collapsed {
  border-radius: 0 0 0.5rem 0.5rem;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-header .collapsed:focus .smiles-header-text{
  text-decoration: underline;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-header .btn:focus .smiles-header-text {
  text-decoration: underline;
}

/* Angular 14/Bootstrap Button Update for above rule */
ngb-accordion.smartmiles-help .accordion-item .accordion-header .accordion-button:focus .smiles-header-text {
  text-decoration: underline;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-header:hover {
  background-color: #d3d3d3;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-header:focus {
  background-color: #d3d3d3;
}

ngb-accordion.smartmiles-help .accordion-item:first-child a{
  border-radius: .5rem .5rem 0 0;
  border:1px solid #0072cf;
}

ngb-accordion.smartmiles-help .accordion-item:last-child a{
  border-radius: 0 0 .5rem .5rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smartmiles-help .accordion-item:last-child a:focus{
  border-radius: 0 0 0rem 0rem;
  border:1px solid #0072cf;
  border-top:0px solid #0072cf;
}

ngb-accordion.smartmiles-help .accordion-item:first-child {
  border-radius: 0;
}
ngb-accordion.smartmiles-help .accordion-item .accordion-header {
  border-right: 1px solid #0072cf;
  border-left: 1px solid #0072cf;
  border-bottom: 1px solid #0072cf;
  border-top: 0;
  border-radius: 0 0 0 0;
  background-color: #FFFFFF;
}
ngb-accordion.smartmiles-help .accordion-item:first-child .accordion-header {
  border-radius: .5rem .5rem 0 0;
  border-top: 1px solid #0072cf;
}
ngb-accordion.smartmiles-help .accordion-item:only-child .accordion-header a{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}
ngb-accordion.smartmiles-help .accordion-item:only-child .accordion-header.active a{
  border-radius: .5rem .5rem 0rem 0rem;
  border:1px solid #0072cf;
}
ngb-accordion.smartmiles-help .accordion-item:only-child a:focus{
  border-radius: .5rem .5rem .5rem .5rem;
  border:1px solid #0072cf;
}

ngb-accordion.smartmiles-help .accordion-item:only-child .accordion-header {
  border: 1px solid #0072cf!important;
  border-radius: .5rem .5rem .5rem .5rem;
  background-color: #FFFFFF;
}


/*ngb-accordion.smartmiles-help .accordion:last-child .accordion-header {
  border-top: 0!important;
}*/

/* .accordion-body style below taken from _card.scss during Angular 14 upgrade */
.accordion-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

ngb-accordion.smartmiles-help .accordion-item a{
  border-radius: 0 0 0 0;
  border-bottom:1px solid #0072cf;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
}

ngb-accordion.smartmiles-help .accordion-item .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
}
ngb-accordion.smartmiles-help .accordion-item:last-child .accordion-body{
  /*padding: 0 0 0rem 0;*/
  background-color: #d7d7d7;
  border-left:1px solid #0072cf;
  border-right:1px solid #0072cf;
  border-bottom:1px solid #0072cf;
  border-radius: 0 0 .5rem .5rem;
}
ngb-accordion.smartmiles-help .accordion-item .accordion-body a{
  border-radius: 0 0 0rem 0rem;
  border:0px solid #0072cf;
}

ol.faq-number-list {
  margin-top: 1rem;
}

ol.faq-number-list li {
  list-style: decimal;
  font-size: 1.25rem;
  margin: 0 0 1rem 0rem;
  font-weight:700;
  line-height: 1.1;
}

ol.faq-number-list li span {
  font-size: 1rem;
  font-weight:400;
}

.custom-modal-overlay {
  z-index: 1060 !important;
  background: rgba(0, 0, 0, 0.4);
}

.payment-infographic-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.estimated-payment-pod-help {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #403a60;
    padding: 1.125rem 1rem;
    color: #ffffff;
    font-weight: 300;
}

.estimated-payment-pod-header-help {
    text-align: center;
    line-height: 0.8;
    font-weight: 700;
    font-size: 1.5rem;
}

.payment-formula-header-help {
    margin-top: 10px;
    line-height: 0.8;
    font-weight: 700;
    color: #6f5091;
    margin-bottom: 10px;
}

.math-signs-help {
    font-weight: 700;
}

.payment-formula-sub-container-help {
    width: 135px;
    line-height: 0.8;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.mileage-rate-help {
    display: flex;
    flex-direction: row;
}

.payment-formula-sub-container-help div:first-child {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment-formula-math-sign-help {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #6f5091;
    display: flex;
}

.payment-formula-help{
    padding-top:30px;
    line-height: 0.8;
    outline: 2px solid #a09daf;
    outline-offset: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 125px;
    width: 150px;
  }

  .payment-formula-mileage-help{
    line-height: 0.8;
    outline: 2px solid #a09daf;
    outline-offset: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 375px;
    height: 200px;
    margin-bottom:10px;
  }

  #teconsent {
    a {
        color: white;
    }
    a:hover {
      color: #0072cf;
    }
    a:hover {
      color: #0072cf;
    }
  }

  @media (min-width:942px){

    .payment-infographic-faq{
      padding:0 2rem;
    }
    .payment-formula-header-help{
      font-size:2rem;
    }
    .math-signs-help{
      font-size: 3rem;
    }

    .estimated-payment-pod-help{
      padding:1.125rem 1rem;
      height:125px;
      width:150px;
    }
    .estimated-payment-pod-header-help{
      text-align: center;
      line-height: 0.8;
      font-size:1.5rem;
      font-weight:700;
    }
  }

  @media (max-width:941.99px) and (min-width:768px){

    .payment-infographic-faq{
      padding:0 0rem;
    }
    .payment-formula-header-help{
      font-size:1.5rem;
    }
    .math-signs-help{
      font-size: 2rem;
    }
    .estimated-payment-pod-help{
      padding:1rem 1rem;
      height:130px;
      width:130px;
    }
    .estimated-payment-pod-header-help{
      text-align: center;
      line-height: 0.8;
      font-size:1.5rem;
      font-weight:700;
    }
  }

  @media (max-width:767.99px) and (min-width:500px){

    .payment-infographic-faq{
      padding:0 0rem;
    }
    .payment-formula-header-help{
      font-size:1.5rem;
    }
    .math-signs-help{
      font-size: 2rem;
    }
    .estimated-payment-pod-help{
      padding:1rem 1rem;
      height:130px;
      width:130px;
    }
    .estimated-payment-pod-header-help{
      text-align: center;
      line-height: 0.8;
      font-size:1.5rem;
      font-weight:700;
    }
  }

  @media (max-width:499.99px){

    .payment-infographic-faq{
      padding:0 0rem;
    }
    .payment-formula-header-help{
      font-size:1.5rem;
    }
    .math-signs-help{
      font-size: 2rem;
    }
    .estimated-payment-pod-help{
      padding:1rem 1rem;
      height:130px;
      width:130px;
    }
    .estimated-payment-pod-header-help{
      text-align: center;
      line-height: 0.8;
      font-size:1.5rem;
      font-weight:700;
    }
  }

  @media (max-width: 767.99px){

    .payment-infographic-faq{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .payment-formula-help{
      height: 130px;
      width: 130px;
      padding-top:30px;
      line-height: 0.8;
      outline: 2px solid #a09daf;
      outline-offset: 4px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
    .payment-formula-math-sign-help{
      margin-top:10px;
      font-weight:700;
      font-size: 2rem;
      text-align: center;
      align-items: center;
      color: #6f5091;
      display: flex;
    }
    .payment-formula-mileage-help{
      width: 275px;
      height: 350px;
      line-height: 0.8;
      outline: 2px solid #a09daf;
      outline-offset: 4px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
    .mileage-rate-help {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .payment-formula-sub-container-help{
      width: 135px;
      line-height: 0.8;
      display: flex;
      flex-direction: column;
      text-align:center;
      align-items: center;
    }

  }

  #sm2_daily_accordion .accordion-item .accordion-header {
    border-right: 1px solid #707070;
    border-left: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }

  #sm2_daily_accordion .accordion-item:first-child .accordion-header {
    border-top: 1px solid #707070;
  }

  #sm2_daily_accordion .accordion-item .accordion-body {
    padding: 0 0 0rem;
    background-color: #d7d7d7;
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
